var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "省", clearable: "" },
                  on: { change: _vm.handlerprovince },
                  model: {
                    value: _vm.province,
                    callback: function ($$v) {
                      _vm.province = $$v
                    },
                    expression: "province",
                  },
                },
                _vm._l(_vm.provincelist, function (listd) {
                  return _c("el-option", {
                    key: listd.id,
                    attrs: { label: listd.title, value: listd.id },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px", margin: "0px 10px" },
                  attrs: { placeholder: "市", clearable: "" },
                  on: { change: _vm.handlercity },
                  model: {
                    value: _vm.city,
                    callback: function ($$v) {
                      _vm.city = $$v
                    },
                    expression: "city",
                  },
                },
                _vm._l(_vm.citylist, function (list1) {
                  return _c("el-option", {
                    key: list1.id,
                    attrs: { label: list1.title, value: list1.id },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "县", clearable: "" },
                  on: { change: _vm.handlerarea },
                  model: {
                    value: _vm.area,
                    callback: function ($$v) {
                      _vm.area = $$v
                    },
                    expression: "area",
                  },
                },
                _vm._l(_vm.arealist, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.title, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "keyword" } },
            [
              _c("el-input", {
                attrs: { placeholder: "企业id或名称" },
                model: {
                  value: _vm.form.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "联系人或手机号" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "is_close" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.form.is_close,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_close", $$v)
                    },
                    expression: "form.is_close",
                  },
                },
                _vm._l(_vm.status, function (lists) {
                  return _c("el-option", {
                    key: lists.id,
                    attrs: { label: lists.name, value: lists.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "area_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { placeholder: "归属" },
                  model: {
                    value: _vm.form.vest_in,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vest_in", $$v)
                    },
                    expression: "form.vest_in",
                  },
                },
                _vm._l(_vm.Attribution, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.truename, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "from" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: { placeholder: "来源" },
                  model: {
                    value: _vm.form.from,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "from", $$v)
                    },
                    expression: "form.from",
                  },
                },
                _vm._l(_vm.from, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerInquire },
                },
                [_vm._v("查 询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: {
                    click: function () {
                      _vm.$refs["form"].resetFields()
                      _vm.province = ""
                      _vm.city = ""
                      _vm.area = ""
                    },
                  },
                },
                [_vm._v(" 重 置 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { stripe: "", data: _vm.tableData } },
        [
          _vm._l(_vm.colemd, function (list, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                label: list.label,
                align: list.align,
                prop: list.prop,
                width: "",
              },
            })
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleradmin(row)
                          },
                        },
                      },
                      [_vm._v("基础资料")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handlerbasis(row)
                          },
                        },
                      },
                      [_vm._v("单据维护")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("basis", { ref: "basis" }),
      _c("basic-information", { ref: "basic" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }